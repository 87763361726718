import React, { PropsWithChildren } from 'react';
import {
  BookmarkModalPopupContent,
  BookmarkModalPopupOverlay,
  BookmarkModalPopupWrapper,
} from './fdr-bookmark-modal-popup.styles';

const FdrBookmarkModalPopupMobileWrapper: React.FC<
  PropsWithChildren<{ open: boolean }>
> = ({ children, open }) => {
  return (
    <BookmarkModalPopupWrapper open={open}>
      <BookmarkModalPopupOverlay />
      <BookmarkModalPopupContent open={open}>
        {children}
      </BookmarkModalPopupContent>
    </BookmarkModalPopupWrapper>
  );
};

export default FdrBookmarkModalPopupMobileWrapper;
